<div class="layout-container flex gap-4">
  @if ((isSmallerThanLarge$ | async) === false) {
    <div class="sidebar-container w-80 px-4 shrink-0">
      <div class="filters-container my-8">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
      </div>
    </div>
  }

  <div class="main-container grow p-4">
    <div class="flex justify-between items-center mt-4 max-sm:mt-0 mb-6">
      <div data-cy="products-title" class="title">Products</div>
    </div>

    <!-- Filter button, conditionally showed just on mobile -->
    @if (isSmallerThanLarge$ | async) {
      <div class="mobile-container my-8">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
      </div>
    }

    <dp-product-list
      (clickProductDetails)="onClickProductDetails($event)"></dp-product-list>
  </div>
</div>

<!-- Templates -->
<ng-template #filters>
  <!-- TODO: add later  -->
  <dp-product-filters></dp-product-filters>
</ng-template>
