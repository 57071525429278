<div class="flex flex-col">
  <ev-accordion [(isOpened)]="isOpened">
    <div class="accordion-header">
      @if ($visibleCategory(); as visibleCategory) {
        <div
          class="flex items-center gap-1 cursor-pointer"
          (click)="
            onSelectIndustryCategory(
              $event,
              visibleCategory.parent ? visibleCategory.parent.id : null
            )
          "
          [ngClass]="{ 'hover:underline': visibleCategory.parent !== null }">
          @if (visibleCategory.parent !== null) {
            <atom-icon
              icon-id="atom:core:arrow_back"
              class="text-xl"></atom-icon>
          }
          <span class="text-xl font-medium">{{ visibleCategory.name }}</span>
        </div>
      } @else {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>

    @if ($visibleCategory()?.children?.length) {
      <div class="accordion-content">
        @for (category of $visibleCategory()?.children; track category) {
          <ul class="category-items">
            <li
              class="text-lg cursor-pointer hover:underline"
              (click)="onSelectIndustryCategory($event, category.id)">
              {{ category.name }}
            </li>
          </ul>
        }
      </div>
    }
  </ev-accordion>
</div>
