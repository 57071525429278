@if ($visibleProducts(); as visibleProducts) {
  @if ($numberOfFilteredProducts()) {
    <div
      [evShimmerEffect]="$loading()"
      data-cy="product-counter"
      class="sub-heading max-sm:mb-4 mb-8 inline-block">
      <span>Showing {{ $numberOfFilteredProducts() }} products</span>
    </div>
  }

  @if (visibleProducts.length > 0) {
    <div class="product-list">
      <div class="list-header mb-4">
        <div
          class="sort-container w-60 max-lg:w-full"
          [evShimmerEffect]="$loading()">
          <atoms-dropdown
            data-cy="sort-dropdown"
            [label]="sortLabel"
            class="sorting-dropdown"
            size="small"
            atomsControl2
            [(ngModel)]="sortingDropdownModel"
            (ngModelChange)="onSortingChange($event)">
            @for (sortingOption of sortingOptions; track sortingOption) {
              <atoms-option [value]="sortingOption.type">
                {{ sortingOption.title }}
              </atoms-option>
            }
          </atoms-dropdown>
        </div>

        @if ($paginatorData(); as paginatorData) {
          <ev-paginator
            [evShimmerEffect]="$loading()"
            data-cy="paginator"
            [numberOfPages]="paginatorData.numberOfPages"
            [activePageIndex]="paginatorData.activePageIndex"
            (activatePage)="onActivatePage($event)"></ev-paginator>
        }
      </div>

      <!-- List -->
      @for (product of visibleProducts; track product) {
        <dp-product-list-item
          data-cy="product-list-item"
          [shimmerEffect]="$loading()"
          class="ev-row"
          (clickProductDetails)="onClickProductDetails($event)"
          [product]="product"></dp-product-list-item>
      }

      <div class="list-footer mt-2 max-lg:mt-4">
        @if ($paginatorData(); as paginatorData) {
          <div class="description" [evShimmerEffect]="$loading()">
            <span>
              {{ paginatorData.activePageIndex * PAGE_SIZE + 1 }} -
              {{
                paginatorData.activePageIndex * PAGE_SIZE +
                  visibleProducts.length
              }}
              of {{ $numberOfFilteredProducts() }} products</span
            >
          </div>
        }

        @if ($paginatorData(); as paginatorData) {
          <ev-paginator
            [evShimmerEffect]="$loading()"
            [numberOfPages]="paginatorData.numberOfPages"
            [activePageIndex]="paginatorData.activePageIndex"
            (activatePage)="onActivatePage($event)"></ev-paginator>
        }
      </div>
    </div>
  } @else {
    <p [evShimmerEffect]="$loading()">There are no available products.</p>
  }
} @else {
  <atom-progress-spinner></atom-progress-spinner>
}
