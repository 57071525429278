<ev-accordion [isOpened]="false">
  <div class="accordion-header">
    <mat-slide-toggle
      (change)="onToggleChange($event)"
      class="example-margin"
      color="primary"
      [checked]="checked"
      [disabled]="disabled">
      <div class="toggle-label">
        <span class="title-text text-lg font-bold ml-1 cursor-pointer">
          {{ filterInfo.title }}
        </span>
        @if (filterInfo.hintText) {
          <atom-tooltip position="right" class="z-50">
            <atom-icon
              slot="invoker"
              [iconId]="'atom:action:info'"
              class="filter-tooltip-icon mx-1 cursor-help"></atom-icon>
            <div slot="content" class="z-50">
              {{ filterInfo.hintText }}
            </div>
          </atom-tooltip>
        }
      </div>
    </mat-slide-toggle>
  </div>
</ev-accordion>
