<ev-accordion [(isOpened)]="isOpened">
  <div class="accordion-header text-lg">
    <span class="title-text">{{ title }}</span>

    @if (hintText) {
      <atom-tooltip position="right" class="z-50">
        <atom-icon
          slot="invoker"
          [iconId]="'atom:action:info'"
          class="filter-tooltip-icon mx-1 cursor-help"></atom-icon>
        <div slot="content" class="z-50">
          {{ hintText }}
        </div>
      </atom-tooltip>
    }
  </div>

  <div class="accordion-content options grid gap-2 mt-4">
    @if (selectType === "radio") {
      <atom-radio-group
        label=""
        atomsControl
        [formControl]="groupControl"
        (change)="onChangeByUser($any($event))">
        @for (option of options; track trackById($index, option)) {
          <atom-radio-button
            [label]="option.title"
            [choiceValue]="option.id"
            [disabled]="disabled"
            (click)="onClickRadioButton(option)"></atom-radio-button>
        }
      </atom-radio-group>
    }

    @if (selectType === "checkbox") {
      <atom-checkbox-group
        label=""
        atomsControl
        [formControl]="groupControl"
        (change)="onChangeByUser($any($event))">
        @for (option of options; track trackById($index, option)) {
          <atom-checkbox
            [label]="option.title"
            [choiceValue]="option.id"></atom-checkbox>
        }
      </atom-checkbox-group>
    }
  </div>

  <div
    [ngClass]="visibleSelections.length > 0 ? 'mt-4' : ''"
    class="closed-content">
    @for (option of visibleSelections; track option) {
      <ev-chip
        [hideCloseIcon]="false"
        (click)="onRemoveSelectedId(option.id)"
        [content]="option.title"></ev-chip>
    }

    @if (this.selectedOptions.length > 3) {
      <ev-chip
        [hideCloseIcon]="true"
        [content]="'+' + (selectedOptions.length - 3)"></ev-chip>
    }
  </div>
</ev-accordion>
